<!-- 企业入驻 查看企业信息 -->
<template>
    <div style="text-align: left;">
        <el-card>
            <div class="pageCenter apply-container">
                <div
                        v-show="
                        enterpriseEnterStatus === this.$oucy.enterpriseEnterStatus.WAITING_FOR_APPLY
                        ||
                        enterpriseEnterStatus === this.$oucy.enterpriseEnterStatus.APPLY_REFUSED||
                        enterpriseEnterStatus === this.$oucy.enterpriseEnterStatus.APPLY_SUCCESS_ENTERPRISE
                        "
                >
                    <el-form :model="enterApplyForm" :rules="enterApplyFormRules" ref="enterApplyForm" label-width="120px">
                        <el-form-item>
                            <h1 v-if="enterApplyForm.id">修改企业信息</h1>
                            <h1 v-else>企业入驻申请</h1>
                        </el-form-item>
                        <el-form-item label="入驻分站" prop="site">
                            <el-select v-model="enterApplyForm.site" placeholder="请选择" style="width: 100%;">
                              <el-option
                                  v-for="item in sites"
                                  :key="item.id"
                                  :label="item.siteName"
                                  :value="item.id">
                              </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="入驻身份" prop="identity">
                            <!-- <el-radio-group v-model="enterApplyForm.identity">
                                <template v-for="item in identitys">
                                    <el-radio border :label="item.id" :value="item.id">{{item.identityName}}</el-radio>
                                </template>
                            </el-radio-group> -->
                              <el-cascader
                                style="width: 100%;"
                                v-model="enterApplyForm.identity"
                                :options="ltIdentityList"
                                :props="{ expandTrigger: 'hover' }"
                                @change="handleChange"></el-cascader>
                        </el-form-item>
                        <el-form-item label="企业图标" prop="avata">     
                            <upload-image @uploadImageBack="uploadImageBack">
                                <img v-if="enterApplyForm.avata" :src="this.$oucy.ossUrl+enterApplyForm.avata" class="avatar">
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </upload-image>
                        </el-form-item>
                        <el-form-item label="企业名称" prop="name">
                            <el-autocomplete
                                    v-model="enterApplyForm.name"
                                    style="width: 100%"
                                    :fetch-suggestions="querySearchAsync"
                                    placeholder="填写企业全称"
                                    @select="handleSearchSelect"
                            >
                                <template slot-scope="{ item }">
                                    <div class="name">
                                        <span class="search-addr-name">{{ item.name }}</span>
                                        <span class="search-addr-address">{{ item.legal_person_name }}</span>
                                        <span class="search-addr-tel">{{ item.reg_date }}</span>
                                    </div>
                                </template>
                                <el-button @click="enterApplyForm.name=null" v-if="!!enterApplyForm.name" type="text" style="padding-right:10px;color:#0366C3;" slot="suffix">清空</el-button>
                            </el-autocomplete>
                        </el-form-item>
                        <el-form-item label="统一信用代码" prop="number">
                            <el-input v-model="enterApplyForm.number" autocomplete="off" placeholder="填写信用代码" disabled></el-input>
                        </el-form-item>
                        <el-form-item label="企业店铺名称" prop="enterpriseShopName">
                            <el-input v-model="enterApplyForm.enterpriseShopName"  placeholder="填写企业店铺名称" maxlength="20" show-word-limit></el-input>
                        </el-form-item>
                       <el-form-item label="企业电话" prop="enterprisePhone">
                            <el-input v-model="enterApplyForm.enterprisePhone"  placeholder="填写企业电话"></el-input>
                        </el-form-item>
                        <el-form-item label="签名" prop="sign">
                            <el-input v-model="enterApplyForm.sign" autocomplete="off" placeholder="填写企业签名" maxlength="50" show-word-limit></el-input>
                        </el-form-item>
                        <el-form-item label="企业简介" prop="describer">
                            <div style="">
                      <!--           <quill-editor
                                        class='editor'
                                        ref="myQuillEditor"
                                        v-model="enterApplyForm.describer"
                                        :options="quillEditorOption"
                                /> -->
                                <el-input
                                  type="textarea"
                                  :autosize="{ minRows: 6, maxRows: 10}"
                                  placeholder="请输入内容"
                                  v-model="enterApplyForm.describer" maxlength="500" show-word-limit>
                                </el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="企业照片/视频" prop="enterpriseImage">                            
                            <MyMedia v-if="enterApplyForm.enterpriseImage && enterApplyForm.enterpriseImage.length" :size="146" :srcList="enterApplyForm.enterpriseImage" :isShowDel="true" @clickDel="clickDel" />
                            <upload v-if="!enterApplyForm.enterpriseImage || enterApplyForm.enterpriseImage.length<9" @uploadBack="uploadBack"><i class="el-icon-plus avatar-uploader-icon uploader"></i></upload>
                        </el-form-item>
                        <el-form-item label="城市" prop="cityCode" v-if="isShowCityCode">
                            <el-cascader v-model="enterApplyForm.cityCode" style="width: 100%" :props="cityProps" :placeholder="enterApplyForm.cityPlaceholder"></el-cascader>
                        </el-form-item>
                        <el-form-item label="营业地址" prop="addrJY">
                            <el-input v-model="enterApplyForm.addrJY" type="text" :rows="2" autocomplete="off" placeholder="填写营业地址" @focus="enterApplyForm.enterpriseAddrLatitude?'':onAddrSelect(addrPoint.JYDZ)"/>
                            <el-button @click="onAddrSelect(addrPoint.JYDZ)" icon="el-icon-location" type="text">选择</el-button>
                        </el-form-item>
                        <el-form-item label="工厂地址" prop="addrGC">
                            <el-input v-model="enterApplyForm.addrGC" type="text" :rows="2" autocomplete="off" placeholder="填写工厂地址" @focus="enterApplyForm.enterpriseAddrGcLatitude?'':onAddrSelect(addrPoint.GCDZ)"/>
                            <el-button @click="onAddrSelect(addrPoint.GCDZ)" icon="el-icon-location" type="text">选择</el-button>
                        </el-form-item>
                        <el-form-item label="展厅地址" prop="addrZT">
                            <el-input v-model="enterApplyForm.addrZT" type="text" :rows="2" autocomplete="off" placeholder="填写展厅地址" @focus="enterApplyForm.enterpriseAddrZtLatitude?'':onAddrSelect(addrPoint.ZTDZ)"/>
                            <el-button @click="onAddrSelect(addrPoint.ZTDZ)" icon="el-icon-location" type="text">选择</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-checkbox v-model="enterApplyForm.agreement">同意
                                <span :underline="false" style="color:#0366C3;" type="primary" @click.stop="$oucy.openNewPage('/AfficheDetail?id=7b52fcb618a947749a7b6673110cfa79&type=1')">《乐搜家企业入驻服务条款》
                                </span>
                                及
                                <span :underline="false" style="color:#0366C3;" type="primary" @click.stop="$oucy.openNewPage('/AfficheDetail?id=3a7b1b07076d47b6a83b5187fae8406e&type=1')">《隐私协议》</span>
                            </el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button @click="submitApply" type="primary" :disabled="!enterApplyForm.agreement" v-if="enterApplyForm.id">提交修改申请</el-button>
                            <el-button @click="submitApply" type="primary" :disabled="!enterApplyForm.agreement" v-else>提交入驻申请</el-button>
                            <el-button @click="$oucy.replace('/acc/index', null)">取  消</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div v-show="
                enterpriseEnterStatus === this.$oucy.enterpriseEnterStatus.APPLYING_FOR_ENTERPRISE
                ||
                enterpriseEnterStatus === this.$oucy.enterpriseEnterStatus.APPLYING_FOR_AGENCY
                "
                style="text-align: center;">
                    <h2>审核结果</h2>
                    <div style="padding: 100px 0 0 0;">
                        <img src="@/assets/applying.jpg">
                        <div style="line-height: 60px;color: #2090ff;font-size: 20px;">提交成功，正在审核</div>
                        <div style="font-size: 15px;">您的入驻申请已提交，客户正在快速审核中！</div>
                    </div>
                    <div style="padding: 130px 0 0 0;">
                        <el-button @click="$oucy.replace('/acc')">返回</el-button>
                    </div>
                </div>
            </div>
        </el-card>

        <input
            style="display: none"
            :id="hiddenFileInputId"
            type="file"
            name="file"
            multiple
            accept="image/jpg, image/jpeg, image/png, image/gif"
            @change="realEditorUploadImg"
        />

        <addr :mapVisible="addrSelectVisible" @close="addrSelectVisible=false" @choose="addrSelectHandle"/>

    </div>

</template>

<script>

    const toolbarOptions = [
        ["bold", "italic",], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
        //["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
        [{header: 1}, {header: 2}], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
        //[{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
        //[{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
        //[{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
        //[{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
        //[{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
        //[{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
        //[{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
        //[{ font: [] }], // 字体种类-----[{ font: [] }]
        //[{ align: [] }], // 对齐方式-----[{ align: [] }]
        //["clean"], // 清除文本格式-----['clean']
        ["image"] // 链接、图片、视频-----['link', 'image', 'video']
    ];
    import oss from "@/util/oss.js"
    import oucy from "@/util/oucyUtil";
    import addr from "@/components/Addr";
    import uploadImage from "@/components/uploadImage";
    import {localSet, localGet, localDel} from "@/store/store"
    import {ltIdentity,realnameauthentication} from "@/service"
    export default {
        name: "EnterpriseApply1111",
        components: {
            addr,
            uploadImage
        },
        data() {
            return {
                identitys: [],
                sites:[],
                enterpriseEnterStatus:null,
                enterApplyForm: {
                    identity:null,
                    id: null,
                    name: null,
                    avata:null,
                    number:null,
                    sign:null,
                    site:null,
                    describer: null,
                    cityPlaceholder:'请选择',
                    cityCode:null,
                    enterpriseShopName:null,
                    addrJY:null,
                    addrGC:null,
                    addrZT:null,
                    agreement:null,
                    enterpriseImage:[],
                    enterprisePhone:null,
                },
                enterApplyFormRules: {
                    identity: [
                        {required: true, message: '请选择认证身份类别', trigger: 'blur'},
                    ],
                    avata: [
                        {required: true, message: '请输入企业图标', trigger: 'blur'},
                    ],
                    name: [
                        {required: true, message: '请输入企业名称', trigger: 'blur'},
                    ],
                    number: [
                        {required: true, message: '请输入企业社会信用代码', trigger: 'blur'},
                    ],
                    enterpriseShopName: [
                        {required: true, message: '请输入企业店铺名称', trigger: 'blur'},
                    ],
                    enterprisePhone: [
                        {required: true, message: '填写企业电话', trigger: 'blur'},
                    ],
                    sign: [
                        {required: true, message: '请输入企业签名', trigger: 'blur'},
                    ],
                    enterpriseImage: [
                        {required: true, message: '请上传企业照片/视频', trigger: 'blur'},
                    ],
                    site: [
                        {required: true, message: '请选择入驻分站', trigger: 'blur'},
                    ],
                    describer: [
                        {required: true, message: '请输入企业简介', trigger: 'blur'},
                    ],
                    cityCode: [
                        {required: true, message: '请输入所在城市', trigger: 'blur'},
                    ],
                    addrJY: [
                        {required: true, message: '请输入企业经营地址', trigger: 'blur'},
                    ]
                },
                quillEditorOption: {
                    placeholder: '填写企业简介',
                    theme: "snow",
                    modules: {
                        toolbar: toolbarOptions,
                        imageDrop: true,
                        imageResize: {
                            displayStyles: {
                                backgroundColor: "black",
                                border: "none",
                                color: "white"
                            },
                            modules: ["Resize", "DisplaySize", "Toolbar"]
                        },
                    },
                },
                hiddenFileInputId: "hiddenFileInputId",
                cityProps:{
                    lazy: true,
                    lazyLoad (node, resolve) {
                        const { level } = node;
                        oucy.findChina(level === 0 ? null : node.value).then(res=>{
                            resolve(Array.from(res).map(item=>({
                                value: item.chinaId,
                                label: item.chinaName,
                                leaf: !item.hasChildren
                            })));
                        });
                    }
                },
                addrSelectVisible: false,
                addrSelectPoint:null,
                addrPoint:{
                    JYDZ: 1,
                    GCDZ: 2,
                    ZTDZ: 3
                },
                enterpriseId:null,
                ltIdentityList:[],
                isShowCityCode:false,
            }
        },
        mounted() {
            console.log('11111111111111111111111111111111111111111111111111111')
            const me = this;
            me.queryAllSite().then(res=>{
              me.sites = res;
              // this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", function(image){
              //   return new Promise((resolve, reject) => {
              //     if(image){
              //       let fileInput = document.getElementById(me.hiddenFileInputId);
              //       fileInput.click();
              //     }
              //     resolve(image);
              //   });
              // });
              // 查询企业入驻状态
              let enterprise=localGet('enterprise')
              if(enterprise && enterprise.id){
                this.enterpriseId=enterprise.id
                oucy.queryEnterApplyStatus({enterpriseId:enterprise.id}).then(res=>{
                  console.log(res);
                  me.enterpriseEnterStatus = res.status;
                  return oucy.queryIdentity(true);
                }).then(res=>{
                  me.identitys = res;
                  if(me.enterpriseEnterStatus === oucy.enterpriseEnterStatus.APPLY_REFUSED){
                    oucy.queryEnterApplyInfo({enterpriseId:enterprise.id}).then(res=>{
                      console.log(res);
                      me.enterApplyForm.identity = res.ltIdentity.idPath;
                      me.enterApplyForm.site = res.logicSite?res.logicSite.id:null;
                      me.enterApplyForm.id = res.id;
                      me.enterApplyForm.avata = res.enterpriseAvata;
                      me.enterApplyForm.name = res.enterpriseName;
                      me.enterApplyForm.number = res.enterpriseCode;
                      me.enterApplyForm.sign = res.enterpriseSignature;
                      me.enterApplyForm.describer = res.enterpriseIntro;
                      me.enterApplyForm.cityCode=res.china.idPath;
                      me.enterApplyForm.cityPlaceholder=res.china.namePath;
                      me.enterApplyForm.addrJY = res.enterpriseAddr;
                      me.enterApplyForm.addrGC = res.enterpriseAddrGc;
                      me.enterApplyForm.addrZT = res.enterpriseAddrZt;
                      me.enterApplyForm.enterpriseShopName = res.enterpriseShopName;
                      me.enterApplyForm.enterprisePhone = res.enterprisePhone;
                      me.enterApplyForm.enterpriseImage = res.enterpriseImage && JSON.parse(res.enterpriseImage);
                      this.isShowCityCode=true
                    });
                  }else{
                    if(me.identitys && me.identitys.length){
                      // me.enterApplyForm.identity=me.identitys[0].id
                    }
                  }
                });
                this.findEnterpriseByLtUserAuthId(enterprise.id)
              }else{
                oucy.queryIdentity(true).then(res=>{
                  me.identitys = res;
                  if(me.enterpriseEnterStatus === oucy.enterpriseEnterStatus.APPLY_REFUSED){
                    oucy.queryEnterApplyInfo({enterpriseId:enterprise.id}).then(res=>{
                      console.log(res);
                      me.enterApplyForm.identity = res.ltIdentity.idPath;
                      me.enterApplyForm.site = res.logicSite?res.logicSite.id:null;
                      me.enterApplyForm.id = res.id;
                      me.enterApplyForm.avata = res.enterpriseAvata;
                      me.enterApplyForm.name = res.enterpriseName;
                      me.enterApplyForm.number = res.enterpriseCode;
                      me.enterApplyForm.sign = res.enterpriseSignature;
                      me.enterApplyForm.describer = res.enterpriseIntro;
                      me.enterApplyForm.cityCode=res.china.idPath;
                      me.enterApplyForm.cityPlaceholder=res.china.namePath;
                      me.enterApplyForm.addrJY = res.enterpriseAddr;
                      me.enterApplyForm.addrGC = res.enterpriseAddrGc;
                      me.enterApplyForm.addrZT = res.enterpriseAddrZt;
                      me.enterApplyForm.enterpriseShopName = res.enterpriseShopName;
                      me.enterApplyForm.enterprisePhone = res.enterprisePhone;
                      me.enterApplyForm.enterpriseImage = res.enterpriseImage && JSON.parse(res.enterpriseImage);

                    });
                  }else{
                    if(me.identitys && me.identitys.length){
                      // me.enterApplyForm.identity=me.identitys[0].id
                    }
                  }
                })
                this.enterpriseEnterStatus=0
              }
              this.findAllLtIdentity()
              setTimeout(()=>{
                  this.isShowCityCode=true
              },2000)
            });
        },
        methods:{
            queryAllSite: function (){
              return new Promise((resolve, reject) => {
                oucy.postRequest('/client/enterprise/logicsite/getAllLogicSite',{siteLatitude:null,siteLongtitude:null,a:123}).then(res=>{
                  console.log(res);
                  resolve(res);
                }).catch(err=>{
                  reject(err);
                })
              });

            },
            handleChange(value) {
                   console.log(value);
                 },
            findAllLtIdentity(){
                ltIdentity.findAllLtIdentity({identityIsEnterprise:true}).then(res=>{
                    if(res && res.length){
                        this.setOPtionsValue(res)
                        this.ltIdentityList=res
                    }
                })
            },
            // 处理多级选择的数据
            setOPtionsValue(list){
                for (let v of list){
                    v.value= v.id
                    v.label= v.identityName
                    if(v.children&&v.children.length){
                        this.setOPtionsValue(v.children)
                    }
                }
            },
            querySearchAsync(queryString, cb) {
                const me = this;
                if(!!queryString && queryString.length>3){
                    oucy.postRequest('/client/enterprise/enterpriseinfo/fuzzyQueryCompanyInfo',{
                        keywords: queryString
                    }).then(res=>{
                        if(res.list && res.list.length>0){
                            cb(res.list);
                        }else{
                            cb([]);
                        }
                    }).catch(err=>{
                        cb([]);
                    })
                } else {
                    cb([]);
                }
            },
            handleSearchSelect: function(item){
                const me = this;
                me.enterApplyForm.name = item.name;
                oucy.postRequest('/client/enterprise/enterpriseinfo/getCompanyBaseInfo',{
                    companyName: item.name
                }).then(res=>{
                    me.enterApplyForm.number = res.registerData.creditNo;
                });
            },
            findEnterpriseByLtUserAuthId: function(enterpriseId){
                const me = this;
                // me.enterApplyForm.name = item.name;
                oucy.postRequest('/client/enterprise/enterpriseinfo/findEnterpriseByLtUserAuthId',{
                    enterpriseId:enterpriseId
                }).then(res=>{
                    if(res && res.id){
                        me.enterApplyForm.identity=res.ltIdentity && res.ltIdentity.idPath
                        // me.enterApplyForm.identity=res.china.idPath
                        me.enterApplyForm.avata=res.enterpriseAvata
                        me.enterApplyForm.name=res.enterpriseName
                        me.enterApplyForm.number=res.enterpriseCode
                        me.enterApplyForm.sign=res.enterpriseSignature
                        me.enterApplyForm.describer=res.enterpriseIntro
                        me.enterApplyForm.cityCode=res.china.idPath
                        // me.enterApplyForm.cityCode.push(...res.china.idPath)
                        me.enterApplyForm.cityPlaceholder=res.china && res.china.namePath;
                        me.enterApplyForm.addrJY=res.enterpriseAddr
                        me.enterApplyForm.addrGC=res.enterpriseAddrGc
                        me.enterApplyForm.addrZT=res.enterpriseAddrZt   
                        me.enterApplyForm.site=res.logicSite?res.logicSite.id:null   
                        me.enterApplyForm.id=res.id   
                        me.enterApplyForm.enterpriseShopName=res.enterpriseShopName   
                        me.enterApplyForm.enterprisePhone=res.enterprisePhone   
                        me.enterApplyForm.enterpriseImage=res.enterpriseImage && JSON.parse(res.enterpriseImage)   
                        me.enterApplyForm.agreement=true  
                        this.isShowCityCode=true
                        me.enterApplyForm.enterpriseAddrLatitude=res.enterpriseAddrLatitude
                        me.enterApplyForm.enterpriseAddrLongtitude=res.enterpriseAddrLongtitude
                        me.enterApplyForm.enterpriseAddrGcLatitude=res.enterpriseAddrGcLatitude
                        me.enterApplyForm.enterpriseAddrGcLongtitude=res.enterpriseAddrGcLongtitude
                        me.enterApplyForm.enterpriseAddrZtLatitude=res.enterpriseAddrZtLatitude
                        me.enterApplyForm.enterpriseAddrZtLongtitude=res.enterpriseAddrZtLongtitude
                    }
                    console.log(res.china.idPath)
                    console.log(me.enterApplyForm.cityCode)
                    // me.enterApplyForm.number = res.registerData.creditNo;
                });
            },
            realEditorUploadImg: function () {
                const me = this;
                return new Promise((resolve, reject) => {
                    let files = document.getElementById(me.hiddenFileInputId).files;
                    if(files.length > 0){
                        me.mqUpload(files, 0);
                    }
                    resolve();
                });
            },
            mqUpload: function (files, index) {
                const me = this;
                let myQuillEditor = me.$refs.myQuillEditor.quill;
                if(files.length > 0 && index < files.length){
                    let Oss=new oss()
                    Oss.startUpload(files[index]).then(res=>{
                    // me.$oucy.uploadRequest(files[index]).then(res=>{
                        let rangeIndex = myQuillEditor.getSelection();
                        myQuillEditor.insertEmbed(rangeIndex == null ? 0 : rangeIndex.index, 'image', me.$oucy.ossUrl + res);
                        index += 1;
                        if(index === files.length){
                            document.getElementById(me.hiddenFileInputId).value = "";
                        } else {
                            me.mqUpload(files, index);
                        }
                    }).catch(err=>{
                        document.getElementById(me.hiddenFileInputId).value = "";
                    })
                }
            },
            onAddrSelect: function(point) {
                this.addrSelectVisible = true;
                this.addrSelectPoint = point;
            },
            addrSelectHandle: function(addr){
                console.log('addr::',addr)
                const me = this;
                this.addrSelectVisible = false;
                let thisAddr = addr.address+'('+addr.name+')';
                if(!!me.addrSelectPoint){
                    if(me.addrSelectPoint===me.addrPoint.JYDZ){
                        me.enterApplyForm.enterpriseAddrLatitude=addr.location.lat
                        me.enterApplyForm.enterpriseAddrLongtitude=addr.location.lng
                        me.enterApplyForm.addrJY = thisAddr;
                        this.$refs['enterApplyForm'].clearValidate('addrJY')
                    } else if(me.addrSelectPoint===me.addrPoint.GCDZ){
                        me.enterApplyForm.addrGC = thisAddr;
                        me.enterApplyForm.enterpriseAddrGcLatitude=addr.location.lat
                        me.enterApplyForm.enterpriseAddrGcLongtitude=addr.location.lng
                        this.$refs['enterApplyForm'].clearValidate('addrGC')
                    } else if(me.addrSelectPoint===me.addrPoint.ZTDZ){
                        me.enterApplyForm.addrZT = thisAddr;
                        me.enterApplyForm.enterpriseAddrZtLatitude = addr.location.lat
                        me.enterApplyForm.enterpriseAddrZtLongtitude = addr.location.lng
                        this.$refs['enterApplyForm'].clearValidate('addrZT')
                    }
                }
            },
            submitApply: function () {
                const me = this;
                console.log(me.enterApplyForm);
                me.$refs["enterApplyForm"].validate((valid) => {
                    if (valid) {

                        console.log(me.enterApplyForm);

                        let postData = {
                            enterpriseId:me.enterpriseId,
                            logicSiteId:me.enterApplyForm.site,
                            identityId:me.$oucy.getArrayLast(me.enterApplyForm.identity),
                            chinaId:me.$oucy.getArrayLast(me.enterApplyForm.cityCode),
                            enterpriseAddr:me.enterApplyForm.addrJY,
                            enterpriseAvata:me.enterApplyForm.avata,
                            enterpriseCode:me.enterApplyForm.number,
                            enterpriseIntro:me.enterApplyForm.describer,
                            enterpriseName:me.enterApplyForm.name,
                            enterpriseSignature:me.enterApplyForm.sign,
                            enterpriseAddrGc:me.enterApplyForm.addrGC,
                            enterpriseAddrZt:me.enterApplyForm.addrZT,
                            id:me.enterApplyForm.id,
                            enterpriseShopName:me.enterApplyForm.enterpriseShopName,
                            enterprisePhone:me.enterApplyForm.enterprisePhone,
                            enterpriseImage:me.enterApplyForm.enterpriseImage&&me.enterApplyForm.enterpriseImage.length?JSON.stringify(me.enterApplyForm.enterpriseImage):null,
                            enterpriseAddrLatitude:me.enterApplyForm.enterpriseAddrLatitude?me.enterApplyForm.enterpriseAddrLatitude:0,
                            enterpriseAddrLongtitude:me.enterApplyForm.enterpriseAddrLongtitude?me.enterApplyForm.enterpriseAddrLongtitude:0,
                            enterpriseAddrGcLatitude:me.enterApplyForm.enterpriseAddrGcLatitude?me.enterApplyForm.enterpriseAddrGcLatitude:0,
                            enterpriseAddrGcLongtitude:me.enterApplyForm.enterpriseAddrGcLongtitude?me.enterApplyForm.enterpriseAddrGcLongtitude:0,
                            enterpriseAddrZtLatitude:me.enterApplyForm.enterpriseAddrZtLatitude?me.enterApplyForm.enterpriseAddrZtLatitude:0,
                            enterpriseAddrZtLongtitude:me.enterApplyForm.enterpriseAddrZtLongtitude?me.enterApplyForm.enterpriseAddrZtLongtitude:0,
                        };
                        this.$oucy.keywordFiltr(this.$oucy.objectToStr(postData)).then(res=>{
                            if(me.enterApplyForm.id){
                                oucy.postRequest('/client/enterprise/enterpriseinfo/updateEnterprise',postData).then(res=>{
                                    this.isRealNameAuthentication()
                                    // oucy.replace('/acc/enterprise', null);
                                });
                            }else{
                                oucy.postRequest('/client/enterprise/enterpriseinfo/enterpriseSettled',postData).then(res=>{
                                    this.isRealNameAuthentication()
                                    // oucy.replace('/acc/enterprise', null);
                                });
                            }
                        },err=>{})

                    }
                });
            },

            uploadImageBack(v){
                this.enterApplyForm.avata = v
                console.log(v)
            },
            // 上传视频 图片
            uploadBack(v) {
                if(this.enterApplyForm.enterpriseImage==null){
                    this.enterApplyForm.enterpriseImage=[]
                }
                this.enterApplyForm.enterpriseImage.push(v)
                this.$refs['enterApplyForm'] && this.$refs['enterApplyForm'].clearValidate && this.$refs['enterApplyForm'].clearValidate('enterpriseImage')
            },
            // 点删除
            clickDel(v) {
                this.enterApplyForm.enterpriseImage.splice(v.i, 1)
            },
            // 是否实名
            isRealNameAuthentication(){
                realnameauthentication.isRealNameAuthentication().then(res => {
                    if (res) {
                            oucy.replace('/acc/enterprise', null);
                    } else {
                        this.$confirm('你还没实名认证, 是否现在去认证?', '提示', {
                         confirmButtonText: '现在去认证',
                         cancelButtonText: '下次再认证',
                         type: 'warning'
                       }).then(() => {
                             this.$oucy.go('/acc/realname', null)
                       }).catch(() => {
                            this.$oucy.replace('/acc/enterprise', null);       
                       });

                        // this.$alert('请先进行实名认证', "提示", {
                        //     confirmButtonText: '确定',
                        //     callback: action => {
                        //        this.$oucy.go('/acc/realname', null)
                        //     }
                        // });
                    }
                })
            }

        }
    }
</script>

<style scoped>
    .apply-container {
        width: 500px;
        min-height: 600px;
        padding: 0 0 50px 0;
    }

    .editor {
        line-height: normal !important;
        height: 200px;
    }

    .editor >>> .ql-toolbar.ql-snow {
        border-radius: 4px 4px 0 0;
    }

    .editor >>> .ql-container.ql-snow {
        border-radius: 0 0 4px 4px;
    }

    .search-addr-name{
        color: #000000;
    }
    .search-addr-address{
        color: #5c5b75;
        font-size: 12px;
        padding: 0 10px;
    }
    .search-addr-tel{
        font-style: italic;
    }

    .avatar-uploader >>> .el-upload {
        border: 1px #d9d9d9 dashed;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        float: left;
        margin: 0 8px 8px 0;
    }
    .avatar-uploader >>> .el-upload:hover {
        border-color: #409EFF;
    }
  
    .cropper-content .cropper {
            width: auto;
            height: 300px;
    }
    .avatar-uploader-icon{
        border: 1px dashed #f1f1f1;
    }
</style>
